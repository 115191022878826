
const Content1 = () => {
  return (
    <div
      id="about"
      className="h-[610px] md:h-[600px] lg:h-[610px] bg-[#15623e] text-center flex flex-col items-center relative overflow-hidden"
    >
      <div
        data-aos="fade-down"
        data-aos-duration="2000"
        className="md:absolute md:right-16 lg:top-[12%] xl:w-[40%] lg:w-[45%] md:top-[10%] md:w-[53%]"
      >
        <p className="font-bold text-[26px] text-[#FFFFFF] lg:text-[48px] lg:w-[447px] text-left lg:leading-[54px] pt-10 mx-10 font-serif md:text-[38px] md:w-[450px] tracking-wide shadow-lg">
          One-Stop Solution for All Service Needs.
        </p>

        <p className="font-normal text-xs md:text-lg text-justify text-[#EDF3F0] md:w-[430px] mt-5 md:mt-1 lg:mt-5 mx-10 font-inter">
          At AtoZ, we pride ourselves on offering a wide array of services tailored to meet your every need. Whether you require foreclosure or estate cleanouts, lawn care, power washing, or professional painting, our experienced team is committed to delivering exceptional results. We handle the heavy lifting, ensuring your property remains clean, safe, and beautifully maintained. Trust AtoZ for all your service needs!
        </p>

        {/* More Button */}
        <div className="mt-6 mx-10">
          <a
            href="#footer" // Adjust this to the actual section ID
            className="inline-block px-6 py-3 font-semibold text-white bg-gradient-to-r from-[#0eaf50] to-[#0c8c3f] rounded-full hover:shadow-md hover:from-[#0c8c3f] hover:to-[#0eaf50] transition duration-300"
          >
            More
          </a>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="absolute -bottom-[45%] md:-bottom-[50%] lg:-bottom-[50%] md:-left-10 xl:left-10 lg:left-5"
      >
        <div className="w-[500px] h-[500px] md:w-[550px] md:h-[550px] lg:w-[600px] lg:h-[600px] xl:w-[667px] xl:h-[667px] bg-[#F0F0F0] rounded-full"></div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="2500"
        className="absolute -bottom-0 translate-x-10 mx-auto md:left-24 lg:left-40"
      >
        <img
          className="w-full h-[350px] md:w-[310px] md:h-[360px] lg:w-[340px] lg:h-[400px] xl:w-[420px] xl:h-[480px] object-cover"
          src={"https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/466610506_122159212820267811_3255692839511888235_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeEJWUmTBUasBrHwls-EBHTE3Vm9XSU3NxPdWb1dJTc3E4V39lRKIiElt0IPmS_nEiWza8LHu8nAa6ATpKTPyXcb&_nc_ohc=jUWAHJfCmjkQ7kNvgG6g8zM&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=AOqHqnqA-p-iWDUsN4vk0mb&oh=00_AYAzdJcBa02HWM6FXJ9JW9RjFbHMicFB0axxxAKhthUlKQ&oe=6735D745"}
          alt="Iphoneimg"
        />
      </div>
    </div>
  );
};

export default Content1;
