import Hero from "./Hero";
import Brands from "./Brands";
import Content1 from "./Content1";
import Content2 from "./Content2";
//import Pricing from "./Pricing";
import Testimonial from "./Testimonial";
import {Helmet} from "react-helmet";
//import CTX from "./CTA";
import Footer from "./Footer";
import Content3 from "./Content3";
import Services from "./servicebanner";

const Evseek = () => {
  return (
    <div className="overflow-x-hidden">
      {/* All the components for this one page application name with reference of figma design */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>AtoZ Business</title>
        <link rel="canonical" href="http://truhani.online/"/>
        <meta name="description" content="HomeCare,Lawncare, Floor Clean,Lock Changing Services"></meta>
      </Helmet>
      <Hero />
      <Brands />
      <Services />
      <Content1 />
      <Content2 />
      <Content3 />
      {/* <Pricing /> */}
      <Testimonial />
      {/* <CTX /> */}
      <Footer />
    </div>
  );
};

export default Evseek;
