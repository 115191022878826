import "./Carousel.css";
import testimonial from "../assets/Testimonial/testi.svg";
import testimonial2 from "../assets/Testimonial/testi2.svg";
import testimonial3 from "../assets/Testimonial/testi3.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
export default function Carousel() {
  return (
    <>
      <div
        style={{ backgroundColor: "#FFFFFF" }}
        className="md:hidden w-[90%] h-[65vh]"
      >
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {/* shadow-[0px_34px_104px_rgba(18,22,28,0.1)] */}
          <SwiperSlide>
            {" "}
            <div className="bg-[#FFFFFF] w-[227px] h-[312px] rounded-[10px] flex flex-col items-center pt-5">
              <img
                style={{ width: "66px", height: "66px" }}
                src={testimonial}
                alt="img1"
              />
              <p className="font-normal font-inter text-xs text-center text-[#121D12] mx-5 mt-[16px]">
              AtoZ-Business transformed my lawn into a lush, green paradise! Their expertise and attention to detail are unmatched. 
            I highly recommend them for all your lawn care needs.”
              </p>
              <p className="font-semibold font-inter text-sm text-center text-[#121D12] mx-5 mt-[21px]">
              Jenny Wilson
              </p>
              <p className="font-normal font-inter text-xs text-center text-[#525B52] mx-5 mt-1">
                House Owner
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-[#FFFFFF] w-[227px] h-[312px] rounded-[10px] flex flex-col items-center pt-5">
              <img
                style={{ width: "66px", height: "66px" }}
                src={testimonial2}
                alt="img1"
              />
              <p className="font-normal font-inter text-xs text-center text-[#121D12] mx-5 mt-[16px]">
                “I'm thrilled with AtoZ-Business's service! My lawn looks healthier than ever, and the technicians are always professional and courteous. 
            Thank you, AtoZ-Business.”
              </p>
              <p className="font-semibold font-inter text-sm text-center text-[#121D12] mx-5 mt-[21px]">
              Michael S
              </p>
              <p className="font-normal font-inter text-xs text-center text-[#525B52] mx-5 mt-1">
              Office Manager
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-[#FFFFFF] w-[227px] h-[312px] rounded-[10px] flex flex-col items-center  pt-5">
              <img
                style={{ width: "66px", height: "66px" }}
                src={testimonial3}
                alt="img1"
              />
              <p className="font-normal font-inter text-xs text-center text-[#121D12] mx-5 mt-[16px]">
                “AtoZ-Business's mosquito defense service is a game-changer! We can finally enjoy our backyard without worrying about pesky mosquitoes. 
            Thank you, AtoZ-Business, for making our outdoor space enjoyable again!”
              </p>
              <p className="font-semibold font-inter text-sm text-center text-[#121D12] mx-5 mt-[21px]">
              Emily P
              </p>
              <p className="font-normal font-inter text-xs text-center text-[#525B52] mx-5 mt-1">
              Government Officer
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
