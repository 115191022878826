import mainLogo from "../assets/navbar/mainLogo.png";
import twitterIcon from "../assets/footer/twitter.svg";
import facebookIcon from "../assets/footer/facebook.svg";
import instaIcon from "../assets/footer/insta.svg";
import githubIcon from "../assets/footer/github.svg";
import copyWrite from "../assets/footer/copyWrite.svg";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-[#f0f5f9] to-[#e0e7ff] py-12 md:py-16 text-[#181B18]">
      <div 
      id="footer"
      
      className="container mx-auto flex flex-col items-center md:flex-row md:justify-between space-y-8 md:space-y-0 md:space-x-10 px-6">

        {/* Logo and Tagline */}
        <div className="flex flex-col items-center md:items-start space-y-4">
          <img
            className="w-48 h-auto" // Adjusted for responsive sizing
            src={mainLogo}
            alt="AtoZ Logo"
          />
          <p className="text-center md:text-left text-sm leading-tight max-w-xs text-[#525B52]">
          AtoZ Services provides reliable property solutions, including cleanouts, lawn care, and painting, ensuring a pristine and professionally maintained space for every client.  </p>
        </div>

        {/* Contact Information */}
        <div className="text-center md:text-left space-y-4">
          <h2 className="font-semibold text-lg text-[#2d3748]">Contact Us</h2>
          <ul className="text-sm space-y-2">
            <li><span>Email:</span> <a href="mailto:contact@AtoZ.com" className="hover:text-[#4f46e5] underline">contact@atozpropertymanagement.com</a></li>
            <li><span>Phone:</span> <a href="tel:+14846870431" className="hover:text-[#4f46e5] underline">+1 (484) 687-0431</a></li>
            <li><span>Location:</span> Norristown servicing, the tri-state area</li>
          </ul>
        </div>

        {/* Quick Links */}
        <div className="text-center md:text-left space-y-4">
          <h2 className="font-semibold text-lg text-[#2d3748]">Quick Links</h2>
          <ul className="text-sm space-y-2">
            <li><a href="#about" className="hover:text-[#4f46e5] underline">About Us</a></li>
            <li><a href="#services" className="hover:text-[#4f46e5] underline">Our Services</a></li>
            <li><a href="#reviews" className="hover:text-[#4f46e5] underline">Reviews</a></li>
          </ul>
        </div>

        {/* Policies */}
        <div className="text-center md:text-left space-y-4">
          <h2 className="font-semibold text-lg text-[#2d3748]">Policies</h2>
          <ul className="text-sm space-y-2">
            <li><a href="/privacy-policy" className="hover:text-[#4f46e5] underline">Privacy Policy</a></li>
            <li><a href="/terms-conditions" className="hover:text-[#4f46e5] underline">Terms & Conditions</a></li>
            <li><a href="/support" className="hover:text-[#4f46e5] underline">Support</a></li>
          </ul>
        </div>
      </div>

      {/* Social Media and Copyright */}
      <div className="container mx-auto mt-10 flex flex-col md:flex-row items-center justify-between text-sm text-[#525B52] px-6">
        {/* Social Media Icons */}
        <div className="flex space-x-6">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <img className="w-6 h-6 hover:opacity-80 transition-opacity" src={twitterIcon} alt="Twitter" />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img className="w-6 h-6 hover:opacity-80 transition-opacity" src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img className="w-6 h-6 hover:opacity-80 transition-opacity" src={instaIcon} alt="Instagram" />
          </a>
        </div>
        <li><span>Developed By:</span> <a href="https://dev.truhani.online" className="hover:text-[#4f46e5] underline"> Hani.Co</a></li>

        {/* Copyright Section */}
        <div className="text-center md:text-left mt-6 md:mt-0 flex items-center space-x-2">
          <img src={copyWrite} alt="Copyright Icon" className="w-5 inline-block" />
          <span>&copy; 2024 AtoZ Solutions. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
