import Tesla from "../assets/Brands/tesla.png";
import Bramo from "../assets/Brands/brammo.png";
import Nio from "../assets/Brands/nio.png";
import Drako from "../assets/Brands/drako.png";
import bestPrice from "../assets/Brands/carpet.svg";
import quik from "../assets/Brands/lock.svg";
import foreclosure from "../assets/Brands/foreclosure.svg";
import wash from "../assets/Brands/wash.svg";
import carpet from "../assets/Brands/carpet.svg";
import paint from "../assets/Brands/paint.svg";
import brick from "../assets/Brands/brick.svg";
import concrete from "../assets/Brands/concrete.svg";
import estate from "../assets/Brands/estate.svg";
import cleanout from "../assets/Brands/clean.svg";
import floorclean from "../assets/Brands/floorclean.svg";
import demolition from "../assets/Brands/demolition.svg";
import ev from "../assets/Brands/b.svg";
import support from "../assets/Brands/support.svg";
import CarouselBrands from "./CarouselBrands";

const Brands = () => {
  return (
    <>
      {/* Brands and feature section here  */}
      <div id="demo" className="bg-[#FFFFFF] h-auto">
        {/* feature section start  */}
        <div className="flex justify-center align-middle items-center mt-16">
          <hr className="w-[50vw] text-center" />
        </div>
        <div className="flex flex-col">
          <p
            data-aos="zoom-out"
            className="font-semibold text-2xl md:text-4xl lg:text-[42px] lg:leading-[46px] text-[#000000] text-center mx-[10vw] mt-10 font-jost tracking-wider"
          >
            What we offer?
          </p>
          <div className="flex flex-col md:flex-row justify-between p-[10%] md:pt-0 md:mt-[80px]">
            {/* Left Column */}
            <div className="md:w-1/2" data-aos="zoom-in-right">
              <div className="flex flex-col">
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={estate}
                    alt="foreclosure icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm md:text-[18px] text-[#000000] font-Plus">
                      Foreclosure Cleanouts:
                    </p>
                    <p className="font-normal text-xs md:text-base md:leading-6 text-[#545954] font-Plus">
                      We efficiently handle foreclosure cleanouts,
                      removing unwanted items and debris to prepare properties for sale or new occupants.
                    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={wash}
                    alt="wash icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm md:text-[18px] text-[#000000] font-Plus">
                    Power Washing:
                    </p>
                    <p className="font-normal text-xs md:text-base md:leading-6 text-[#545954] font-Plus">
                    We offer power washing services to remove dirt, grime, and stains from various surfaces, improving curb appeal and cleanliness.
                    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={demolition}
                    alt="demolition icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm md:text-[18px] text-[#000000] font-Plus">
                     Small Demolition Projects:
                    </p>
                    <p className="font-normal text-xs md:text-base md:leading-6 text-[#545954] font-Plus">
                    We handle small demolition projects safely and efficiently, removing unwanted structures while minimizing disruption to your property.
                    </p>
                  </div>
                </div>
                
                {/* Additional services in left column */}
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={cleanout}
                    alt="cleanout icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                      Estate Cleanouts:
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                      Our estate cleanout service helps families by respectfully clearing out belongings, ensuring a smooth transition during challenging times.
                    </p>
                  </div>
                </div> 
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={ foreclosure}
                    alt="bestprice icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                      Eviction Cleanouts:
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                      We provide swift eviction cleanouts, clearing out properties to restore them for future tenants while adhering to legal guidelines.
                    </p>
                  </div>
                </div> 
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={floorclean}
                    alt="floorclean icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                      Floor Buffing and Waxing:
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                      Our floor buffing and waxing service revitalizes your floors, enhancing their appearance and prolonging their lifespan with professional care.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="md:w-1/2" data-aos="zoom-in-left">
              <div className="flex flex-col">
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={support}
                    alt="bestprice icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                    Lawn Care Services:
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                    Our lawn care services include mowing, trimming, and fertilization, ensuring your yard looks healthy and vibrant throughout the seasons.
                    </p>
                  </div>
                </div>
                
                {/* Additional services in right column */}
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={carpet}
                    alt="carpet icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                    Carpet Cleaning:
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                     We offer deep carpet cleaning services to remove stains, allergens, and odors, leaving your carpets fresh and revitalized.    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={paint}
                    alt="paint icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                    Interior and Exterior PainPainting:  
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                    We provide professional interior and exterior painting services, transforming spaces with quality finishes and attention to detail.   </p>
                  </div>
                </div>
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={quik}
                    alt="bestprice icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                    Lock Change-Outs: 
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                   Our lock change-out services enhance security, ensuring your property is safe with new, high-quality locks installed promptly.
                    </p>
                  </div>
                </div>  <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={concrete}
                    alt="concrete icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                    Lite Concreting Work: 
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                    Our light concreting work includes sidewalks, patios, and small slabs, ensuring durable and aesthetically pleasing results for your outdoor spaces.   </p>
                  </div>
                </div>  
                <div className="flex flex-row gap-5 mt-12 mx-5">
                  <img
                    className="w-[8vw] h-[8vh]"
                    src={brick}
                    alt="brick icon"
                  />
                  <div className="flex flex-col gap-3">
                    <p className="font-bold text-sm text-[#000000] font-Plus md:text-[18px]">
                    Lite Brick Laying: 
                    </p>
                    <p className="font-normal text-xs text-[#545954] font-Plus md:text-base md:leading-6">
                    Our light brick laying service enhances your property’s appearance, adding structural integrity and charm with skilled craftsmanship.   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
