import testimonial from "../assets/Testimonial/testi.svg";
import testimonial2 from "../assets/Testimonial/testi2.svg";
import testimonial3 from "../assets/Testimonial/testi3.svg";
import Carousel from "./Carousel";

const Testimonial = () => {
  return (
    <div
      id="pages"
      className="bg-[#F6FAF6] pb-5 h-[100vh] md:h-[800px] flex flex-col items-center"
    >
      <p
        data-aos="zoom-in-down"
        data-aos-duration="2000"
        className="md:text-[42px] text-[22px] leading-[31px] md:leading-[48px] font-jost font-semibold text-[#181B18] p-16 pb-0 text-center md:w-[636px] w-[380px]"
      >
        Trusted by <span className="text-[#21D57C]">800+</span> Customers
      </p>
      {/* carousel of testimonials for mobile devices  */}
      <Carousel />

      {/* testimonial section for desktops  */}
      <div className="hidden md:flex md:flex-row md:justify-center md:gap-10 md:w-[80%] lg:mt-[80px] md:mt-10">
        {/* 1 */}
        <div
          data-aos="zoom-in-right"
          data-aos-duration="2000"
          className="bg-[#FFFFFF] lg:w-[372px] lg:h-[443px] md:w-[372px] md:h-[520px] rounded-[10px] flex flex-col items-center"
        >
          <img className="mt-[47px]" src={testimonial} alt="img1" />
          <p className="font-normal font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[16px]">
            “AtoZ Business made my property shine! Their foreclosure cleanout service was efficient and thorough. 
            Highly recommend them for any cleanout needs!”
          </p>
          <p className="font-semibold font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[31px]">
            Sarah Johnson
          </p>
          <p className="font-normal font-inter md:text-base text-center text-[#525B52] md:mx-5 md:mt-3">
            Property Manager
          </p>
        </div>
        {/* 2 */}
        <div
          data-aos="zoom-in-up"
          data-aos-duration="2000"
          className="bg-[#FFFFFF] lg:w-[372px] lg:h-[443px] md:w-[372px] md:h-[520px] rounded-[10px] flex flex-col items-center shadow-[0px_34px_104px_rgba(18,22,28,0.1)]"
        >
          <img className="mt-[47px]" src={testimonial2} alt="img1" />
          <p className="font-normal font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[16px]">
            “I'm incredibly satisfied with AtoZ's lawn care service! My yard looks amazing, and the team was professional and friendly. 
            Thank you, AtoZ Business!”
          </p>
          <p className="font-semibold font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[31px]">
            David L.
          </p>
          <p className="font-normal font-inter md:text-base text-center text-[#525B52] md:mx-5 md:mt-3">
            Homeowner
          </p>
        </div>
    {/* 3 */}
<div
  data-aos="zoom-in-left"
  data-aos-duration="2000"
  className="bg-[#FFFFFF] lg:w-[372px] lg:h-[443px] md:w-[372px] md:h-[520px] rounded-[10px] flex flex-col items-center"
>
  <img className="mt-[47px]" src={testimonial3} alt="img1" />
  <p className="font-normal font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[16px]">
    “I've used AtoZ for lawn care, cleanouts, mosquito defense, and junk removal, and each service exceeded my expectations! 
    Their team is reliable and always goes the extra mile. I highly recommend them for any property needs!”
  </p>
  <p className="font-semibold font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[31px]">
    Lisa M.
  </p>
  <p className="font-normal font-inter md:text-base text-center text-[#525B52] md:mx-5 md:mt-3">
    Property Owner
  </p>
</div>

      </div>
     
    </div>
  );
};

export default Testimonial;
