

const Content3 = () => {
  return (
    <div 
    id="content3"
    className=" h-[598px] md:h-[600px] lg:h-[600px] bg-[#FAFAFA] text-center flex flex-col items-center relative overflow-hidden">
      <div
  data-aos="fade-down"
  data-aos-duration="2000"
  className="md:absolute md:right-16 lg:top-[20%] xl:w-[40%] lg:w-[45%] md:top-[10%] md:w-[53%]"
>
<p className="font-semibold text-[22px] text-[#12141D] lg:text-[42px] lg:w-[447px] text-left lg:leading-[48px] pt-10 mx-10 font-public md:text-[36px] md:w-[450px]">
  <span className="whitespace-nowrap">AtoZ-Business's </span> 
  <span className="font-bold bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
    24/7
  </span> 
  <br />
  <span>Call Center Support</span>
</p>

  <p className="font-medium text-xs md:text-lg text-left text-[#12141D] md:w-[430px] mt-5 md:mt-1 lg:mt-5 mx-10 font-inter">
    Experience seamless order processing and user assistance through our specialized call center. 
    Our team is committed to providing efficient service and resolving queries promptly, ensuring your experience with AtoZ-Businesses is smooth and satisfying. 
    Contact us for any assistance or to place your order.
  </p>
  <div className="text-center md:absolute md:right-10 lg:right-0 lg:mt-4 md:mt-3 mt-5">
    <button
      className="bg-[#2b87c6] hover:bg-[#04568c] hover:animate-wiggle w-[100px] h-[35px] md:w-[193px] md:h-[55px] p-3 rounded-lg"
      onClick={() => window.location.href = 'tel:+14846870431'}
    >
      <p className="font-semibold text-xs md:text-base text-[#FFFFFF] font-inter">Call Now</p>
    </button>
  </div>
</div>

      
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="absolute -bottom-[45%]  md:-bottom-[50%] lg:-bottom-[50%] md:-left-10 xl:left-10 lg:left-5"
      >
        <div className="w-[500px] h-[500px] md:w-[550px] md:h-[550px] lg:w-[600px] lg:h-[600px] bg-[#15623e] rounded-full"></div>
      </div>
      <div className="absolute -bottom-[25%] md:left-0 lg:-bottom-[38%] lg:left-12  border-solid w-[400px] h-[400px] lg:w-[550px] lg:h-[550px] rounded-full  border-[#fafafa] border-[1px]"></div>

      <div className="absolute -bottom-[35%] md:-bottom-[30%] lg:-bottom-[35%] left-2 lg:left-20 md:left-5  border-solid w-[450px] md:w-[500px] h-[450px] lg:w-[550px] lg:h-[550px] rounded-full  border-[#fafafa] border-[1px]"></div>

      <div className="absolute -bottom-[35%] lg:-bottom-[35%] -left-16 md:-left-8 lg:-left-4  border-solid w-[450px] md:w-[500px] h-[450px] lg:w-[550px] lg:h-[550px] rounded-full  border-[#fafafa] border-[1px]"></div>

      <div
        data-aos="fade-up"
        data-aos-duration="2500"
        className="absolute -bottom-12 translate-x-10  mx-auto md:left-24 lg:left-40"
      >
        <img
          className="w-full h-[350px] md:w-[250px] md:h-[380px] lg:w-[300px] lg:h-[450px] xl:w-[300px] xl:h-[450px] -rotate-12 object-cover"
          src={"https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/466342824_122159213780267811_6928224753080693463_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=109&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeEMEw97XsL_KCrU9fwT4FJDfO8mVvzrdAh87yZW_Ot0CIXilFj2ofvFtOHYpxM48Vrft9l1nBfggWh9keVa0rhS&_nc_ohc=NPAvGkiAFvQQ7kNvgEARHaV&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=A8grSZ0p4qEVlw_xxwE6JPS&oh=00_AYBoG_TBi44XlayfzNTIkez9u8V4QhmIYdVmQuyPt_YBOQ&oe=673607FE"}
      
        />
      </div>
    </div>
  );
};

export default Content3;
