import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./Navbar";

const Hero = () => {
    const phoneNumber = "+1 (484) 687-0431";
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fade, setFade] = useState('fade-in');
    const [nextImageIndex, setNextImageIndex] = useState(1);

    const texts = [
        "Foreclosure Cleanouts",
        "Estate Cleanouts",
        "Eviction Cleanouts",
        "Floor Buffing and Waxing",
        "Power Washing",
        "Lawn Care Services",
        "Small Demolition Projects",
        "Light Concreting Work",
        "Interior and Exterior Painting",
        "Light Brick Laying",
        "Carpet Cleaning",
        "Lock Change-Outs",
    ];

    // Use URLs instead of local assets for images
    const images = [
        "https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/465796406_122159211914267811_1621455139122093310_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeE22Y18oI9VK6dbYM-BADtEekhDiaA4icR6SEOJoDiJxGOLxXuQgCH5KITMChdrscMMNm5ddCS0T_JexmclPZIj&_nc_ohc=DztuzJ9yR6EQ7kNvgESsAxV&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=AQ11Her1Nxd1huMYt1AX7WG&oh=00_AYDDe_8NGkT3ZrceITVLOC4nkT219-oXU01bN1wdw8QG3g&oe=6735E821",
     "https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/465786252_122159212076267811_9214168689323215088_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeH41NcSZzN3G7exTMay9jvVxBERtET99AnEERG0RP30CcHEKa-rv8AHc5DUF3CXqYGKTaz85QihJRkm2Ok3rlI1&_nc_ohc=LVKShJiz6EQQ7kNvgG2Vv-V&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=AnXXAElafcrnBApAzE3vy_r&oh=00_AYBmXV6wnDC_71sxfbHdKHXhbzWxF_HJIU7TMChGQdoLWg&oe=6735E83F",
     "https://scontent.fisb3-4.fna.fbcdn.net/v/t39.30808-6/466085105_122159212160267811_8497608218912240126_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeH_SWnMo79sSYMLdznoEBLGFqjmqqjN4hIWqOaqqM3iEoYVNPId4z50UWPBDbZGfy9zEKtpx0wmJONom6atq4nF&_nc_ohc=1aI0R7yh7G4Q7kNvgGp6apA&_nc_zt=23&_nc_ht=scontent.fisb3-4.fna&_nc_gid=ARrW-Wkm_CwMuznnNNE-jWE&oh=00_AYDN5peUr1EqkjCq7rzpaBfI1fGuSyy1aAw0j3tYRXOI9A&oe=6735DFF9",
     "https://scontent.fisb3-3.fna.fbcdn.net/v/t39.30808-6/465817701_122159212016267811_1206842440838327721_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeGKHwO3tkGTMqnZMN3MyyvhVV67iII4-cVVXruIgjj5xT79SY9WUXfvYqhBpxCv9B35zh-YBzacluUuuWafPCUV&_nc_ohc=lAnbitSmUawQ7kNvgHutxio&_nc_zt=23&_nc_ht=scontent.fisb3-3.fna&_nc_gid=ASDC-iq6MjjRSar6iPslJyl&oh=00_AYC7SUCazGQ78l9UmR-z5cEDHu24l5gOPT-tzpsCayvdFQ&oe=6735EF3E",
     "https://scontent.fisb3-4.fna.fbcdn.net/v/t39.30808-6/465740436_122159211494267811_1558833606682332937_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeEFDstdj0jE_1nX5SwVMdGlFXXAot0ymRYVdcCi3TKZFvy6WDC1Gr-7weF02n_6gI5hw_ylWilcsJA9yY8cmxXu&_nc_ohc=AL7bojhqhE0Q7kNvgFN9RKS&_nc_zt=23&_nc_ht=scontent.fisb3-4.fna&_nc_gid=AU3GcSWo8V5PLwDr6nZxmT6&oh=00_AYB-_7471Oys3Ea6rtfAxyP6Nfs7_p-K6SgFsRVcH5uK1Q&oe=6735D971"
     ];

    useEffect(() => {
        const textInterval = setInterval(() => {
            setFade('fade-out');
            setTimeout(() => {
                setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
                setFade('fade-in');
            }, 500);
        }, 3000);

        return () => clearInterval(textInterval);
    }, []);

    useEffect(() => {
        const imageInterval = setInterval(() => {
            setFade('fade-out');
            setNextImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            setTimeout(() => {
                setCurrentImageIndex(nextImageIndex);
                setFade('fade-in');
            }, 2000);
        }, 5000);

        return () => clearInterval(imageInterval);
    }, [nextImageIndex]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(phoneNumber);
        toast.success('Copied to clipboard!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
        });
    };

    return (
        <>
            <ToastContainer />
            <style>
                {`
          h1 {
            position: relative;
            overflow: hidden;
            transition: opacity 0.5s ease-in-out;
          }
          .fade-in {
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }
          .fade-out {
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
          }
        `}
            </style>

            <div className="bg-[#F3FCF3] pb-[5%] md:h-[800px]">
                <Navbar />
                <div className="md:flex md:flex-row relative md:ml-[10%]">
                    <div data-aos="fade-right" data-aos-duration="1500" className="md:mt-[5%]">
                        <h1 className="font-semibold lg:w-[80%] md:w-[90%] text-4xl md:text-[35px] md:leading-[60px] font-jost text-[#121D17] text-center mt-[5vh] mx-4 md:mx-0 md:text-left">
                            Are You Looking for
                        </h1>
                        <h1
                            className={`font-bold lg:w-[90%] md:w-[100%] text-5xl md:text-[60px] md:leading-[70px] font-jost text-[#04568c] text-center mt-[2vh] mx-4 md:mx-0 md:text-left ${fade} 
    transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg`}>
                            {texts[currentTextIndex]}
                        </h1>

                        <p className="text-center font-normal text-sm md:text-xl mt-[5vh] md:mt-5 mx-4 font-inter lg:w-[60%] md:w-[80%] md:mx-0 md:text-left text-[#121D17]">
                        Enhance your property’s appeal and function with AtoZ’s trusted services.
                        </p>

                        <h1 className="font-semibold lg:w-[90%] md:w-[100%] text-4xl md:text-[50px] md:leading-[50px] font-jost text-[#121D17] text-center mt-[10vh] mx-4 md:mx-0 md:text-left relative">
                            {phoneNumber}
                            <span
                                className="absolute right-lg:w-[50%] top-0 mt-1 mr-2 cursor-pointer"
                                onClick={copyToClipboard}
                                title="Copy Phone Number"
                            >
                                <FaCopy />
                            </span>
                        </h1>
                        <div className="text-center md:absolute lg:mt-4 md:mt-3 mt-5">
                            <button
                                className="bg-[#2b87c6] hover:bg-[#04568c] hover:animate-wiggle w-[100px] h-[35px] md:w-[193px] md:h-[55px] p-3 rounded-lg"
                                onClick={() => window.location.href = 'tel:+14846870431'}
                            >
                                <p className="font-semibold text-xs md:text-base text-[#FFFFFF] font-inter">Call Now</p>
                            </button>
                        </div>
                    </div>

                    <div
                        className="flex flex-col mt-[70px] content-center justify-center items-center relative md:right-[5%] md:w-[618px] md:h-[710px]"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                    >
                        <div
                            className="w-[431.87px] h-[431.87px] lg:w-[652px] lg:h-[652px] bg-[#15623e] rounded-full relative"
                            style={{ backgroundImage: `url("https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/466610506_122159212820267811_3255692839511888235_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeEJWUmTBUasBrHwls-EBHTE3Vm9XSU3NxPdWb1dJTc3E4V39lRKIiElt0IPmS_nEiWza8LHu8nAa6ATpKTPyXcb&_nc_ohc=jUWAHJfCmjkQ7kNvgG6g8zM&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=AOqHqnqA-p-iWDUsN4vk0mb&oh=00_AYAzdJcBa02HWM6FXJ9JW9RjFbHMicFB0axxxAKhthUlKQ&oe=6735D745")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                        ></div>

                        <img
                            className={`w-[700px] h-[350px] absolute lg:-translate-x-[80px] -translate-y-2 lg:-translate-y-5 -translate-x-[34px] ${fade}`}
                            src={images[currentImageIndex]}
                            alt="hero image"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
