import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import your images here
const images = [
   "https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/465796406_122159211914267811_1621455139122093310_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeE22Y18oI9VK6dbYM-BADtEekhDiaA4icR6SEOJoDiJxGOLxXuQgCH5KITMChdrscMMNm5ddCS0T_JexmclPZIj&_nc_ohc=DztuzJ9yR6EQ7kNvgESsAxV&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=AQ11Her1Nxd1huMYt1AX7WG&oh=00_AYDDe_8NGkT3ZrceITVLOC4nkT219-oXU01bN1wdw8QG3g&oe=6735E821",
"https://scontent.fisb3-2.fna.fbcdn.net/v/t39.30808-6/465786252_122159212076267811_9214168689323215088_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeH41NcSZzN3G7exTMay9jvVxBERtET99AnEERG0RP30CcHEKa-rv8AHc5DUF3CXqYGKTaz85QihJRkm2Ok3rlI1&_nc_ohc=LVKShJiz6EQQ7kNvgG2Vv-V&_nc_zt=23&_nc_ht=scontent.fisb3-2.fna&_nc_gid=AnXXAElafcrnBApAzE3vy_r&oh=00_AYBmXV6wnDC_71sxfbHdKHXhbzWxF_HJIU7TMChGQdoLWg&oe=6735E83F",
"https://scontent.fisb3-4.fna.fbcdn.net/v/t39.30808-6/466085105_122159212160267811_8497608218912240126_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeH_SWnMo79sSYMLdznoEBLGFqjmqqjN4hIWqOaqqM3iEoYVNPId4z50UWPBDbZGfy9zEKtpx0wmJONom6atq4nF&_nc_ohc=1aI0R7yh7G4Q7kNvgGp6apA&_nc_zt=23&_nc_ht=scontent.fisb3-4.fna&_nc_gid=ARrW-Wkm_CwMuznnNNE-jWE&oh=00_AYDN5peUr1EqkjCq7rzpaBfI1fGuSyy1aAw0j3tYRXOI9A&oe=6735DFF9",
"https://scontent.fisb3-3.fna.fbcdn.net/v/t39.30808-6/465817701_122159212016267811_1206842440838327721_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeGKHwO3tkGTMqnZMN3MyyvhVV67iII4-cVVXruIgjj5xT79SY9WUXfvYqhBpxCv9B35zh-YBzacluUuuWafPCUV&_nc_ohc=lAnbitSmUawQ7kNvgHutxio&_nc_zt=23&_nc_ht=scontent.fisb3-3.fna&_nc_gid=ASDC-iq6MjjRSar6iPslJyl&oh=00_AYC7SUCazGQ78l9UmR-z5cEDHu24l5gOPT-tzpsCayvdFQ&oe=6735EF3E",
"https://scontent.fisb3-4.fna.fbcdn.net/v/t39.30808-6/465740436_122159211494267811_1558833606682332937_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeEFDstdj0jE_1nX5SwVMdGlFXXAot0ymRYVdcCi3TKZFvy6WDC1Gr-7weF02n_6gI5hw_ylWilcsJA9yY8cmxXu&_nc_ohc=AL7bojhqhE0Q7kNvgFN9RKS&_nc_zt=23&_nc_ht=scontent.fisb3-4.fna&_nc_gid=AU3GcSWo8V5PLwDr6nZxmT6&oh=00_AYB-_7471Oys3Ea6rtfAxyP6Nfs7_p-K6SgFsRVcH5uK1Q&oe=6735D971"
];

const Services = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      id="expert"
      className="services-page bg-gray-100 min-h-[110vh] flex flex-col items-center justify-center"
    >
      <h2 className="text-4xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-900 mb-8 shadow-lg drop-shadow-md">
        Our Main Services
      </h2>

      <div className="w-full md:w-3/4 lg:w-[1120px] pb-12"> {/* Add padding at bottom */}
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Service ${index + 1}`}
                className="w-full h-auto object-cover rounded-lg"
                style={{ width: "100%", height: "auto", maxHeight: "560px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Services;
